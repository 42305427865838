import React from "react";
import { Controller } from "react-hook-form";
import "cleave.js/dist/addons/cleave-phone.us";
import Cleave from "cleave.js/react";
import Select from "react-select";
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker,  MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import {

  RadioGroup,
  FormControlLabel,
  Radio,

} from "@material-ui/core";

import { withStyles } from '@material-ui/core/styles';





// const current = new Date();
// const todays_date = `${("0" + (current.getMonth() + 1)).slice(-2)}/${(
//   "0" + current.getDate()
// ).slice(-2)}/${current.getFullYear()}`;


const defaultMaterialTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#8167a9',
    },
    error: {
      main: '#dd3444cf'
    },

    
    // secondary: {
    //   light: '#0066ff',
    //   main: '#0044ff',
    //   // dark: will be calculated from palette.secondary.main,
    //   contrastText: '#ffcc00',
    // },
  // },
  // typography: {
  //   fontFamily: [
  //     'Montserrat', 
  //     'sans-serif'
  //   ].join(',')
  // },

  overrides: {

    MuiTextField: {

      border: '10px solid red',

    }
  }
  //   MuiInputBase: {
  //       input: {
  //           fontSize: '16px',
  //           fontFamily: 'Montserrat, sans-serif',
  //           fontWeight: 500,
  //           color: '#ffffffe0',

  //           '&:active': {
  //             color: "green",
  //          },
  //       },
  //       root: {
  //         color: 'green'
  //       }
        
  //     },
  //     MuiOutlinedInput: {
  //       notchedOutline: {
  //         border: '2px solid rgba(31,31,31)',
  //         // backgroundColor: 'rgba(31,31,31)',
  //         '&:hover': {
  //           border: '2px solid rgba(31,31,31)',
  //        },
  //       }
  //     }
  },

  overrides: {
    MuiFormControl: {
      root: {
        height: '100%',
        width: '100%',
      }
    },

    MuiInputBase: {
      root: {
        color: "#ffffffe0",
        fontSize: "1rem",
        fontFamily: "Montserrat, sans-serif",
        fontWeight: 500,
        height: '100%',
        paddingLeft: "0px !important",

        "&:focus-within": {
          color: 'white',
          backgroundColor: '#2020208a !important'
        }

      },

    },
    MuiOutlinedInput: {

      input: {
        // paddingBottom: 0,
        // padding: 0,
      },

      root: {
        border: "none",
        

        

        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          border: "none",
        },
        "&:focus-within:not($disabled):not($error) $notchedOutline": {
          boxShadow: '0 0 0 0.2rem rgb(129 104 169 / 14%)',
        },
        "&:not($disabled):not($focused):not($error) $notchedOutline": {
          border: "none",
        },
        "&:not($disabled):not($focused) $notchedOutline": {
          borderWidth: "2px"
        },
      },
    },
    
  },
});




export function MyDateInput
  ({ name, label, optional, error, control, today, setValue, ...rest }) {



    return (
      <>
        <label htmlFor={name}>
          {label}{" "}
          {optional ? <span className="optional"> - optional</span> : null}
        </label>
        <div  className={`form-control ${error ? "is-invalid" : ""}`} style={{ padding:0, border: 'none', marginBottom: '24px' }}>
        <div className="datePicker">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={defaultMaterialTheme}>
        <Controller
          name={name}
          control={control}
          // htmlRef={ref}

          // type="date"   --- this doesn't work well with Cleave
          // className={`form-control ${error ? "is-invalid" : ""}`}
          render={({ ref, onChange,  ...rest }) => (
            <KeyboardDatePicker
              error={error ? true : false }
              clearable
              margin="none"
              InputAdornmentProps={{ position: "start" }}
              // classes="form-control"
              size="small"
              onChange={e => {onChange(e); setValue(name, e, { shouldValidate: true })}}
              id="date-picker-dialog"
              format="MM/dd/yyyy"
              // placeholder=""
              inputVariant="outlined"
              views={["month", "date", "year"]}
              openTo="month"
              // error={false}
              helperText={null}
              {...rest}
            />
          )}
          // {...rest}
          // as={
          //   <KeyboardDatePicker
          //     clearable={optional ? true : false }
          //     // value={selectedDate}
          //     placeholder="10/10/2018"
          //     // onChange={date => handleDateChange(date)}
          //     minDate={new Date()}
          //     format="MM/dd/yyyy"
          //     inputVariant="outlined"
          //     className="form-control"
          //   />
          // }
        />
        </ThemeProvider>
        </MuiPickersUtilsProvider>
        </div>
        </div>
        <p className="applicationError">{error?.message}</p>
      </>
    );
  }




// export const MyDateInput2 = React.forwardRef(
//     ({ name, label, optional, error, control, today, ...rest }, ref) => {
//       return (
//         <>
//           <label htmlFor={name}>
//             {label}{" "}
//             {optional ? <span className="optional"> - optional</span> : null}
//           </label>
//           <Controller
//             name={name}
//             control={control}
//             // htmlRef={ref}
  
//             // type="date"   --- this doesn't work well with Cleave
//             className={`form-control ${error ? "is-invalid" : ""}`}
//             {...rest}
//             as={
//               <Cleave
//                 placeholder={today ? todays_date : "01/30/1990"}
//                 options={{
//                   date: true,
//                   datePattern: ["m", "d", "Y"],
//                   delimiter: "/",
//                 }}
//               />
//             }
//           />
//           <p className="applicationError">{error?.message}</p>
//         </>
//       );
//     }
//   );
  
  export const MyPhoneInput = React.forwardRef(
    ({ name, label, optional, error, control, ...rest }, ref) => {
      return (
        <>
          <label htmlFor={name}>
            {label}{" "}
            {optional ? <span className="optional"> - optional</span> : null}
          </label>
          <Controller
            name={name}
            control={control}
            // htmlRef={ref}
            type="tel"
            className={`form-control ${error ? "is-invalid" : ""}`}
            {...rest}
            as={
              <Cleave
                placeholder="716-555-5555"
                options={{ phone: true, phoneRegionCode: "US", delimiter: "-" }}
              />
            }
          />
          <p className="applicationError">{error?.message}</p>
        </>
      );
    }
  );




  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: '#ffffffe0',
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '1rem',
      fontWeight: 500
    }),
  
    singleValue: (provided, state) => ({
      color: '#ffffffe0',
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '1rem',
      fontWeight: 500,
    }),
  
    control: (provided) => ({ 
      ...provided, 
      border: 'none',
    }),

    placeholder: (provided) => ({ 
      ...provided, 
      color: 'rgb(74,74,74)',
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '1rem',
      fontWeight: 500,
    }),

    container: (provided) => ({ 
      ...provided,
      marginBottom: '24px'
    }),
  }

  const invalidCustomStyles = {
    ...customStyles,
    control: (provided) => ({ 
      ...provided, 
      boxShadow: '0 0 0 2px #dd3444cf',
      border: '#dd3444cf'
    }),
  }
  
  export const MySelectPicker = React.forwardRef(
    ({ name, label, optional, options, error, control, ...rest }, ref) => {
      return (
        <>
          <label className={optional ? "optional" : null}>{label}</label>
          <Controller
            name={name}
            control={control}
            // defaultValue={defaultValue ? defaultValue.value : ""}
            // isClearable={isClearable ? true : false}
            options={options}
            render={(
              { onChange, onBlur, value, name, ref },
            ) => (
            // as={Select}

            // render={({rest}) => (
              <Select
              id={name}
              isClearable={true}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              name={name}
              ref={ref}
              options={options}
              styles={error ? invalidCustomStyles : customStyles}
              theme={theme => ({
                ...theme,
                borderRadius: '.25rem',
                colors: {
                  ...theme.colors,
                  primary: '#8167a9',
                  primary25: '#8167a98c',
                  neutral0: '#202020',
                  neutral80: '#e4e4e4',
                  primary50: "#5e4b7b",
          
                },
              })}

              />
            )}
  
            // className={`form-control ${errors?.[name] ? "is-invalid" : ""}`}
          />
          <p className="applicationError">{error?.message}</p>
        </>
      );
    }
  );
  
  export const MyTextInput = React.forwardRef(
    ({ name, label, optional, error, ...rest }, ref) => {
      return (
        <>
          <label htmlFor={name}>
            {label}{" "}
            {optional ? <span className="optional"> - optional</span> : null}
          </label>
          <input
            name={name}
            ref={ref}
            className={`form-control ${error ? "is-invalid" : ""}`}
            {...rest}
          />
          <p className="applicationError">{error?.message}</p>
        </>
      );
    }
  );

  export const MyTextBoxInput = React.forwardRef(
    ({ name, label, optional, error, ...rest }, ref) => {
      return (
        <>
          <label htmlFor={name}>
            {label}{" "}
            {optional ? <span className="optional"> - optional</span> : null}
          </label>
          <textarea
            name={name}
            ref={ref}
            className={`form-control ${error ? "is-invalid" : ""}`}
            rows="7"
            {...rest}
          />
          <p className="applicationError">{error?.message}</p>
        </>
      );
    }
  );

  export const MyMoneyInput = React.forwardRef(
    ({ name, label, optional, error, control, ...rest }, ref) => {
      return (
        <>
          <label htmlFor={name}>
            {label}{" "}
            {optional ? <span className="optional"> - optional</span> : null}
          </label>
          <Controller
            name={name}
            control={control}
            // htmlRef={ref}
            type="tel"
           
            {...rest}
            render={({ ...rest }) => (
              <Cleave
                className={`form-control ${error ? "is-invalid" : ""}`}
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                  prefix: "$",
                }}
                // style={meta.touched && meta.error ? FormInputValidationStyle : null}
                {...rest}
              />
            )}
          />
          <p className="applicationError">{error?.message}</p>
        </>
      );
    }
  );


//   .MuiIconButton-root:hover {
//     background-color: rgb(135 98 190 / 21%);
// }

  const ColoredRadio = withStyles({
    root: {
      color: '#ffffffe0',
      '&$checked': {
        color: '#8762bf',
      },
      '&:hover': {
        backgroundColor: 'rgb(135 98 190 / 21%)',
      }
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);


export const YesNoInput = ({name, label, control, error, ...rest}) => {
  return (
    <>
    <div className="yesNoPicker">
    <label>{label}{" "}</label>
    <Controller
    {...rest}
    as={
      <RadioGroup>
        <FormControlLabel
          value="Yes"
          control={<ColoredRadio />}
          label="Yes"
        />
        <FormControlLabel 
          value="No" 
          control={<ColoredRadio />} 
          label="No" 
        />
      </RadioGroup>
    }
    name={name}
    control={control}
  />
  </div>
  <p className="applicationError">{error?.message}</p>
  <div className="spacerHeight"></div>
  
  </>
  )
}