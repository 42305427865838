import React, {  useEffect, useState } from "react";
import { useForm } from "react-hook-form";
// import { useHistory } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "./StateMachine/updateAction"
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MyDateInput, MySelectPicker, MyTextInput, MyMoneyInput, MyTextBoxInput} from "./Inputs/inputs"
import { PayFrequencies, YesNo, StateCodes } from "./selectors/selectors";

import Button from "react-bootstrap/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { analytics } from "../../App"


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { YesNoInput } from "./Inputs/inputs"





const SignupSchema = yup.object().shape({
  work: yup.object({
    employed: yup.string().required("Required"),

    employer:  yup.string().when("employed", {
      is: "Yes",
      then: yup.string().required("Required"),
      otherwise: yup.string().notRequired(),
    }),

    role:  yup.string().when("employed", {
      is: "Yes",
      then: yup.string().required("Required"),
      otherwise: yup.string().notRequired(),
    }),


    startDate: yup.mixed().when("employed", {
      is: "Yes",
      then: yup.date().typeError('Invalid Date').required('Required'),
      otherwise: yup.date().notRequired(),
    }),


    hours: yup.string().when("employed", {
      is: "Yes",
      then: yup.string().required("Required"),
      otherwise: yup.string().notRequired(),
    }),

    salary: yup.string().when("employed", {
      is: "Yes",
      then: yup.string().required("Required"),
      otherwise: yup.string().notRequired(),
    }),

    
    payFreq: yup.object().when("employed", {
      is: "Yes",
      then: yup.object().shape({
        value: yup.string().required('Required'),
        label: yup.string().required('Required')
      }).required('Required').nullable('Required'),
      otherwise: yup.object().notRequired(),
    }),
    
    
    salaryConsistent: yup.object().when("employed", {
      is: "Yes",
      then: yup.object().shape({
        value: yup.string().required('Required'),
        label: yup.string().required('Required')
      }).required('Required').nullable('Required'),
      otherwise: yup.object().notRequired(),
    }),

      address:  yup.string().when("employed", {
        is: "Yes",
        then: yup.string().required("Required"),
        otherwise: yup.string().notRequired(),
      }),

      address2:  yup.string(),

      city:  yup.string().when("employed", {
      is: "Yes",
      then: yup.string().required("Required"),
      otherwise: yup.string().notRequired(),
    }),

      state:  yup.object().when("employed", {
      is: "Yes",
      then: yup.object().shape({
        value: yup.string().required('Required'),
        label: yup.string().required('Required')
      }).required('Required').nullable('Required'),
      otherwise: yup.object().notRequired(),
    }),

      zipCode:  yup.string().when("employed", {
      is: "Yes",
      then: yup.string().required("Required"),
      otherwise: yup.string().notRequired(),
    }),

      haveAdditionalInfo: yup.object().when("employed", {
      is: "Yes",
      then: yup.object().shape({
        value: yup.string().required('Required'),
        label: yup.string().required('Required')
      }).required('Required').nullable('Required'),
      otherwise: yup.object().notRequired(),
    }),

    additionalInfo: yup.string().when("employed", {
      is: "Yes",
      then: yup.string().when("haveAdditionalInfo.value", {
        is: "Yes",
        then: yup.string().required("Required"),
        otherwise: yup.string().notRequired(),
      }),
      otherwise: yup.string().notRequired(),
    }),


    unemployedAdditionalInfo: yup.string().when("employed", {
      is: "No",
      then: yup.string().required("Required"),
      otherwise: yup.string().notRequired(),
    }),
  }),

  });


export const  Employment = props => {

    const { state, actions } = useStateMachine({updateAction});
    const { handleSubmit, errors, register, control, setValue, watch } = useForm({
        resolver: yupResolver(SignupSchema),
        mode: "onBlur",
        defaultValues: state.rentalApplication,
        reValidateMode: 'onBlur',
    });

    // const [showIsUnemployedEmployed, setShowIsUnemployed] = useState("No")
    const watchEmployed = watch('work.employed', 'Yes')
    const [openConfirmSkipModal, setOpenConfirmSkipModal] = useState(false)

    // function showConfirmSkipModal() {
    //   setOpenConfirmSkipModal(true)
    // }
  
    function hideConfirmSkipModal() {
      setOpenConfirmSkipModal(false)
    }

    function skipEmployment() {
      // setShowIsUnemployed("Yes")
      // console.log('skip!')
      setValue('work.employed', "No")
      // console.log(state.rentalApplication.work.employed);
      
    }

    function employmentChange(e) {
      // console.log(e.target.value, 'eeeee');
      // setValue('work.employed', e.target.value)
      // setShowIsUnemployed(e.target.value)
    }


    // const { push }  = useHistory();
    const onSubmit = data => {
        actions.updateAction(data);
        // console.log(data, 'employment')

        props.incrementPage()
    }

    useEffect(() => {
      document.querySelector('body').scrollTo(0, 0)
      analytics.logEvent('Employment');
    }, [])

    const watchAdditionalInfo = watch("work.haveAdditionalInfo", false);

    // console.log(watchEmployed, 'watchEmployed')

    return (
        <>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Button
                    className="skipButton"
                    variant="dark shadow-none"
                    onClick={skipEmployment}
                    disabled={watchEmployed === "Yes" ? false : true}
                  >
                    Skip
                  </Button>


                  {/* <MButton className="skipButton" variant="contained" onClick={skipEmployment} color="primary">Skip</MButton> */}



        <h1 className="form-header">Employment</h1>
            <h2 className="form-subtitle">Provide your source of income. Add additional info if needed below.</h2>
          <Card id="employment" className="applicationCard">
          {/* <Card.Header>Length of Stay</Card.Header> */}
          <Card.Body>


          <div style={watchEmployed === "Yes" ? {display: 'none'} : null }>
            <div style={{width: '150px'}}>
          <YesNoInput name="work.employed" label="Are you employed?" error={errors?.work?.employed} control={control} onClick={employmentChange}  />
          </div>
          { watchEmployed === "No" ?
          <MyTextBoxInput name="work.unemployedAdditionalInfo" label="Please specify your source of income if you are not employed." ref={register}  error={errors?.work?.unemployedAdditionalInfo} control={control}  />
          : null }
          </div>



{ watchEmployed === "Yes" ? <>

<Row>
            <Col>
                <MyTextInput name="work.employer" label="Employer" ref={register} error={errors?.work?.employer} />
            </Col>
</Row>

        <Row>
            <Col>
                <MyTextInput name="work.role" label="Role" ref={register} error={errors?.work?.role} />
            </Col>
            <Col>
                <MyDateInput name="work.startDate" label="Start Date"  error={errors?.work?.startDate} control={control} setValue={setValue} />
            </Col>
            <Col>
                <MyTextInput name="work.hours" label="Hours/Week" ref={register} error={errors?.work?.hours} placeholder="40+" />
            </Col>
        </Row>



        <Row>
            <Col>
                <MyMoneyInput name="work.salary" label="Salary" ref={register} error={errors?.work?.salary} control={control}/>
            </Col>
            <Col>
                <MySelectPicker name="work.payFreq" label="Pay Frequency" ref={register} error={errors?.work?.payFreq} control={control} options={PayFrequencies}/>
            </Col>
            <Col>
                <MySelectPicker name="work.salaryConsistent" label="Salary Consistent?" ref={register} error={errors?.work?.salaryConsistent} control={control} options={YesNo}/>
            </Col>
        </Row>




          <Row>
              <Col>
                {/* prettier-ignore */}
                <MyTextInput name="work.address" label="Work Address" ref={register} error={errors?.work?.address} />
              </Col>
              </Row>
              <Row>
              <Col>
                {/* prettier-ignore */}
                <MyTextInput name="work.address2" label="Address 2" ref={register} error={errors?.work?.address2} optional />
              </Col>
            </Row>
            <Row>
              <Col>
                {/* prettier-ignore */}
                <MyTextInput name="work.city" label="City" ref={register}  error={errors?.work?.city} control={control}  />
              </Col>
              <Col>
                {/* prettier-ignore */}
                <MySelectPicker name="work.state" label="State" ref={register} error={errors?.work?.state} control={control} options={StateCodes}/>
              </Col>
              <Col>
                {/* prettier-ignore */}
                <MyTextInput name="work.zipCode" label="Zip Code" ref={register}  error={errors?.work?.zipCode} control={control}  />
              </Col>
            </Row>

            <div className="singleQuestion">
            <MySelectPicker name="work.haveAdditionalInfo" label="Would you like to add a note?" ref={register} error={errors?.work?.haveAdditionalInfo} control={control} options={YesNo}/>
            </div>

            {watchAdditionalInfo?.value === "Yes" ?
            <MyTextBoxInput name="work.additionalInfo" label="Additional Info" placeholder="Additional sources of income" ref={register}  error={errors?.work?.additionalInfo} control={control}  />
            : null}

            </>
            : null}

          </Card.Body>
        </Card>

        <div className="continue-area text-center">
        <input className="continueButton" type="submit" value="Continue" />
          </div>
        </form>

        <ConfirmSkipDialog openConfirmSkipModal={openConfirmSkipModal} hideConfirmSkipModal={hideConfirmSkipModal} incrementPage={props.incrementPage} register={register} errors={errors} control={control}/>


        {/* <DevTool control={control} /> */}
        </>
    );
}


function ConfirmSkipDialog({openConfirmSkipModal, hideConfirmSkipModal, incrementPage, register, errors, control}) {
  return (
    <Dialog
        open={openConfirmSkipModal}
        onClose={hideConfirmSkipModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Skip Employment Info?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please specify your source of income for being able to pay the rent amount on time every month.
          </DialogContentText>

          <MyTextBoxInput name="work.additionalInfo" ref={register}  error={errors?.work?.additionalInfo} control={control}  />


        </DialogContent>
        <DialogActions>
          <Button onClick={hideConfirmSkipModal} className="btn btn-secondary">Go Back</Button>
          <Button onClick={incrementPage} className="btn btn-danger" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
  )
}