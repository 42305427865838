import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
// import { useHistory } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "./StateMachine/updateAction"
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {  MySelectPicker, MyTextInput, MyMoneyInput, MyPhoneInput} from "./Inputs/inputs"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { StateCodes, YesNo, LengthOfStays } from "./selectors/selectors";
import { DevTool } from "@hookform/devtools";
import { analytics } from "../../App"


const SignupSchema = yup.object().shape({
  currentAddress: yup.object().shape({

    address: yup.string().required("Required"),
    address2: yup.string(),
    city: yup.string().required("Required"),
    state: yup.object().shape({
      value: yup.string().required('Required'),
      label: yup.string().required('Required')
    }).required('Required').nullable('Required'),
    zipCode: yup.string().required("Required"),
    lengthOfStay: yup.object().shape({
      value: yup.string().required('Required'),
      label: yup.string().required('Required')
    }).required('Required').nullable('Required'),
    reasonForMoving: yup.string().required("Required"),
    renting: yup.object().shape({
      value: yup.string().required('Required'),
      label: yup.string().required('Required')
    }).required('Required').nullable('Required'),
    rentAmount: yup.string().when("renting.value", {
      is: "Yes",
      then: yup.string().required("Required"),
      otherwise: yup.string().notRequired(),
    }),
    rentUpToDate: yup.object().when("renting.value", {
      is: "Yes",
      then: yup.object().shape({
        value: yup.string().required('Required'),
        label: yup.string().required('Required')
      }).required('Required').nullable('Required'),
      otherwise: yup.object().notRequired(),
    }),
    landlordsPhone: yup.string().when("renting.value", {
      is: "Yes",
      then: yup.string().required('Required').matches(/^(1-)?\d{3}-\d{3}-\d{4}$/, 'Phone number is not valid'),
    }),
    student: yup.object().shape({
      value: yup.string().required('Required'),
      label: yup.string().required('Required')
    }).required('Required').nullable('Required'),
    school: yup.string().when("student.value", {
      is: "Yes",
      then: yup.string().required('Required'),
    }),
  })
  });


export const  CurrentAddress = props => {
    

    const { state, actions } = useStateMachine({updateAction});
    const { handleSubmit, errors, register, control, watch } = useForm({
        resolver: yupResolver(SignupSchema),
        mode: "onBlur",
        defaultValues: state.rentalApplication
    });
    const watchRenting = watch("currentAddress.renting", false);
    const watchStudent = watch("currentAddress.student", false);
    // const { push }  = useHistory();
    const onSubmit = data => {
        actions.updateAction(data);
        // console.log(data, 'currentAddress')

        props.incrementPage()
    }

    useEffect(() => {
      document.querySelector('body').scrollTo(0, 0)
      analytics.logEvent('Current Address');
    }, [])

    return (
        <>
        <form onSubmit={handleSubmit(onSubmit)}>
        <h1 className="form-header">Current Address</h1>
            <h2 className="form-subtitle">Where are you currently living?</h2>
          <Card id="currentAddress" className="applicationCard">
          <Card.Body>
            <Row>
              <Col>
                {/* prettier-ignore */}
                <MyTextInput name="currentAddress.address" label="Address" ref={register} error={errors?.currentAddress?.address} />
              </Col>
              </Row>
              <Row>
              <Col>
                {/* prettier-ignore */}
                <MyTextInput name="currentAddress.address2" label="Address 2" ref={register} error={errors?.currentAddress?.address2} optional />
              </Col>
            </Row>
            <Row>
              <Col>
                {/* prettier-ignore */}
                <MyTextInput name="currentAddress.city" label="City" ref={register}  error={errors?.currentAddress?.city} control={control}  />
              </Col>
              <Col>
                {/* prettier-ignore */}
                <MySelectPicker name="currentAddress.state" label="State" ref={register} error={errors?.currentAddress?.state} control={control} options={StateCodes}/>
              </Col>
              <Col>
                {/* prettier-ignore */}
                <MyTextInput name="currentAddress.zipCode" label="Zip Code" ref={register}  error={errors?.currentAddress?.zipCode} control={control}  />
              </Col>
            </Row>




            <Row>
              <Col>
                {/* prettier-ignore */}
                <MySelectPicker name="currentAddress.lengthOfStay" label="Length" ref={register} error={errors?.currentAddress?.lengthOfStay} control={control} options={LengthOfStays}/>
              </Col>
              <Col>
                {/* prettier-ignore */}
                <MyTextInput name="currentAddress.reasonForMoving" label="Reason for Moving" ref={register}  error={errors?.currentAddress?.reasonForMoving} control={control}  />
              </Col>
            </Row>




            <Row>
              <Col>
                <MySelectPicker name="currentAddress.renting" label="Currently Renting?" ref={register} error={errors?.currentAddress?.renting} control={control} options={YesNo}/>
              </Col>

              <Col>
              {watchRenting?.value === "Yes" ?
                  <MyMoneyInput name="currentAddress.rentAmount" label="Rent" ref={register} error={errors?.currentAddress?.rentAmount} control={control} />
                : null }
              </Col>

              <Col>
                {watchRenting?.value === "Yes" ?
                  <MySelectPicker name="currentAddress.rentUpToDate" label="Rent up to date?" ref={register} error={errors?.currentAddress?.rentUpToDate} control={control} options={YesNo}/>
                : null }
              </Col>

              <Col>
                {watchRenting?.value === "Yes" ?
                  <MyPhoneInput name="currentAddress.landlordsPhone" label="Landlord's Phone" ref={register} error={errors?.currentAddress?.landlordsPhone} control={control} />
                : null }
              </Col>


            </Row>


            <Row>
              <Col>
                <MySelectPicker name="currentAddress.student" label="Are you a student?" ref={register} error={errors?.currentAddress?.student} control={control} options={YesNo}/>
              </Col>

              <Col>
              {watchStudent?.value === "Yes" ?
                <MyTextInput name="currentAddress.school" label="School" ref={register}  error={errors?.currentAddress?.school} control={control}  />
                : null }
              </Col>




            </Row>


          </Card.Body>
        </Card>

        <div className="continue-area text-center">
        <input className="continueButton" type="submit" value="Continue" />
          </div>
          <DevTool control={control} />
        </form>
        </>
    );
}