
export function Page404() {
  return (
    <div>
      <div className="section full-height over-hide">
        <div className="switch-wrap">
          <h2 className="error-main-content">404</h2>
          <div className="boldText">The requested page can't be found</div>
        </div>
      </div>
    </div>
  );
}


export function PageError() {
  return (
    <div>
      <div className="section full-height over-hide">
        <div className="switch-wrap">
          <h2 className="error-main-content">Error</h2>
          <div className="boldText">Oh no! Something went wrong</div>
          <div className="boldText">If you continue to get this error please email buffalocityapartments@gmail.com</div>
        </div>
      </div>
    </div>
  );
}


export function Thanks() {
  return (
    <div>
      <div className="section full-height over-hide">
        <div className="switch-wrap">
          <h2 className="error-main-content">Thanks!</h2>
          <div className="boldText">We'll be in touch!</div>
        </div>
      </div>
    </div>
  );
}
