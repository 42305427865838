import firebase from "firebase";
const config = {
  apiKey: "AIzaSyCfeWmW6IhIpQ67JXQe35gLfzBcUarImOs",
  authDomain: "test-8be12.firebaseapp.com",
  projectId: "test-8be12",
  storageBucket: "test-8be12.appspot.com",
  messagingSenderId: "521953067032",
  appId: "1:521953067032:web:32520b6c8023a10d68436d",
  measurementId: "G-K7TV3N85K6"
};
firebase.initializeApp(config);
export default firebase;
