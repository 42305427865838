import React, {useEffect} from "react";

import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { useStateMachine } from "little-state-machine";
import updateModal from "./StateMachine/updateModal";
import Row from "react-bootstrap/Row";
import { AdditionalOccupantModal } from "./modals/additionalOccupantModal";
import { analytics } from "../../App"


export const  AdditionalOccupantsCard = props => {

  const { actions, state} = useStateMachine({updateModal})
  const occupants = state.rentalApplication.additionalOccupants 
  // console.log(occupants);
  const openModal = () => { actions.updateModal({visible: true, additionalOccupant: occupants.length, editMode: false}) }

  
  useEffect(() => {
    document.querySelector('body').scrollTo(0, 0)
    analytics.logEvent('Additional Occupants');
    }, [])

  function handleContinue() {
    props.incrementPage()
  }

    return (
        <>
                  <h1 className="form-header">Additional Occupants</h1>
            <h2 className="form-subtitle">Please list any additional occupants including kids</h2>

            <div className="text-center addOccupantButton">
            <Button onClick={openModal} disabled={occupants[3] ? true : false} className="addOccupantButton" variant="secondary">Add Occupant</Button>{' '}
            </div>

        <Card id="additionalOccupants" className="applicationCard additionalOccupantsApplicationCard">
          <Card.Body> 
              <AdditionalOccupants openModal={openModal} />
          </Card.Body>
        </Card>

        { occupants[0] == null ? <div style={{height: '200px'}}></div> : null}
        
        <div className="continue-area text-center">
        <input className="continueButton" onClick={handleContinue} type="submit" value="Continue" />
          </div>

        </>
    );
}




export const AdditionalOccupants = ({openModal}) => {
  const { actions, state} = useStateMachine({updateModal})
  const occupants = state.rentalApplication.additionalOccupants   // todo call occupants roommates

  // console.log(modalState, 'modalState')



  // const [modalVisible, setModalVisible] = useState(false)
  // const openModal = () => { actions.updateModal({visible: true}) }
  const openModal0 = () => { actions.updateModal({visible: true, additionalOccupant: 0, editMode: true}) }
  const openModal1 = () => { actions.updateModal({visible: true, additionalOccupant: 1, editMode: true}) }
  const openModal2 = () => { actions.updateModal({visible: true, additionalOccupant: 2, editMode: true}) }
  const openModal3 = () => { actions.updateModal({visible: true, additionalOccupant: 3, editMode: true}) }
  // const openModal = () => setModalVisible(true);
  // const { state } = useStateMachine();
  
  // const [occupantNum, setOccupantNum] = useState(null)


  function AdditionalOccupantDetailsCard({occupant, openModal}) {
      return (
          <Col>
          <div className="additionalOccupantDetailsCard">
              <h5>{occupant.firstName + " " + occupant.lastName}</h5>
              <div className="aoInfo noWrap"><span>Age:</span>{occupant.age}</div> <hr className="line" />
              <div className="aoInfo noWrap"><span>Job:</span>{occupant.job}</div> <hr className="line" />
              <div className="aoInfo noWrap"><span>Rel:</span>{occupant.relationship}</div> <hr className="lastLine" />    
          <button
            type="button"
            className="btn btn-outline-info float-right"
          //   style={xyStyle}
            onClick={openModal}
          >
            {/* <AiOutlineEdit className="editIcon" /> */}
            Edit
          </button>
          </div>
        </Col>
      )
  }



  // function AddOccupantButton() {
  //     return (
  //         <>
  //         <Col>
  //         <div className="addPersonGroup" onClick={openModal}>
  //         <div className="addPerson">
  //           <div className="addPersonContent">
  //           <IoMdPersonAdd className="addPersonGuy" />
  //           <div className="addOccupant">Add Occupant</div>
  //           </div>
  //         </div>
  //         </div>
  //         </Col>
  //         </>
  //     )
  // }

  const OccupantModal = () => {
      return(
        <AdditionalOccupantModal />
      )
  }

  // console.log(occupants, 'occupants')

  if (occupants.length === 0) {
      return (
          <>
          {/* <AddOccupantButton /> */}
          <Col />
          <Col />
          <Col />
          <Col />
          <OccupantModal/>
          </>
      )
  }

  else {
      return (
          <>
          <Row>
          {/* {occupants[3] ? null :  <AddOccupantButton /> } */}
          {occupants[0] ? <AdditionalOccupantDetailsCard occupant={occupants[0]} openModal={openModal0} /> :  null}
          {occupants[1] ? <AdditionalOccupantDetailsCard occupant={occupants[1]} openModal={openModal1} /> :  null}
          {occupants[2] ? <AdditionalOccupantDetailsCard occupant={occupants[2]} openModal={openModal2} /> :  null}
          {occupants[3] ? <AdditionalOccupantDetailsCard occupant={occupants[3]} openModal={openModal3} /> :  null}
          <OccupantModal/>
          </Row>
          </>
      )
  }
}


