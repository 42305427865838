import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { IoMdClose, IoMdArrowBack } from "react-icons/io";
import { useStateMachine } from "little-state-machine";
import updateAction from "./RentalApplicationComponents/StateMachine/updateAction"
import { ContactInfo } from "./RentalApplicationComponents/contactInfo";

import { LengthOfStay } from "./RentalApplicationComponents/lengthOfStay";
import { CurrentAddress } from "./RentalApplicationComponents/currentAddress";
import { Employment } from "./RentalApplicationComponents/employment";
import { AdditionalOccupantsCard } from "./RentalApplicationComponents/additionalOccupantsCard";
import { Questionnaire } from "./RentalApplicationComponents/questionnaire";
import { Thanks } from "./Pages"

import { useHistory } from "react-router-dom";
import { Redirect, useParams } from 'react-router-dom';
import firebase from "./RentalApplicationComponents/firebase/firestore"

import { createStore } from "little-state-machine";

import { analytics } from "../App"


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import { ApartmentInfoPage } from "./ApartmentInfoPage"

// const current = new Date();
// const todays_date = `${("0" + (current.getMonth() + 1)).slice(-2)}/${(
//   "0" + current.getDate()
// ).slice(-2)}/${current.getFullYear()}`;


// var birthday = new Date();
// var moveInDate = new Date();
// birthday.setFullYear( birthday.getFullYear() - 1 );
// moveInDate.setFullYear( moveInDate.getFullYear() + 1 );

const defaultRentalApplicationValues = {
  apartmentInfo: "",
  contactInfo: {
    firstName: "",
    middleName: "",
    lastName: "",
    DoB: null,
    email: "",
    phone: "",
  },
  lengthOfStay: {
    moveInDate: null,
    leaseTerm: "",
  },
  currentAddress: {
    address: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    lengthOfStay: "",
    reasonForMoving: "",
    renting: "",
    rent: "",
    rentUpToDate: "",
    landlordsPhone: "",
    student: "",
    school: "",
  },
  
  work: {
    employed: "Yes",
    employer: "",
    role: "",
    startDate: null,
    hours: "",
    salary: "",
    payFreq: "",
    salaryConsistent: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    haveAdditionalInfo: "",
    additionalInfo: "",
  },
  questions: {
    smoke: "",
    animal: "",
    felony: "",
    funds: "",
  },

  additionalOccupants: [],
}

createStore({
  rentalApplication: defaultRentalApplicationValues,
  modalState: {
    visible: false,
    editMode: false,
    additionalOccupant: null,
  },
});


export function RentalApplication() {
  const [currentPage, setCurrentPage] = useState(1);
  const [openConfirmCloseModal, setOpenConfirmCloseModal] = useState(false)
  const [apartmentInfo, setApartmentInfo] = useState(null)
  const [apartmentInfoViewed, setApartmentInfoViewed] = useState(false)
  const [searchingForApt, setSearchingForApt] = useState(true)
  const numPages = 6;

  const { actions, state } = useStateMachine({updateAction})

  const history = useHistory();
  const { push } = useHistory();

  let { apartmentId } = useParams();
  // console.log(apartmentId, 'theID')

  React.useEffect(() => {
    if (apartmentId != null) {
      const fetchData = async () => {
        // console.log(apartmentId);
        // const data = await firebase
        await firebase
          .firestore()
          .collection("test_collection")
          .doc(apartmentId)
          .get()
          .then(function (doc) {
            if (doc.exists) {
              // console.log("Document data:", doc.data());
              // setApartmentFound(true);
              setApartmentInfo(doc.data());
              setSearchingForApt(false)
              analytics.logEvent('Rental Application Specific', {address: doc.data().address + ' ' + doc.data().address2})
            } else {
              // doc.data() will be undefined in this case
              // console.log("No such document!");
              push('/error')
              // setApartmentFound(false);
            }
            // setLoading(false);
            // console.log("returning", doc);
          })
          .catch(function (error) {
            // console.log("Error getting document:", error);
            push('/error')
            //todo send to error page
          });
      };
      fetchData();
    }
    else {
      // setCurrentPage(1)
      setSearchingForApt(false)
      analytics.logEvent('Rental Application General');
    }
    
    
  }, [apartmentId, push]);







  function showConfirmCloseModal() {
    setOpenConfirmCloseModal(true)
  }

  function hideConfirmCloseModal() {
    setOpenConfirmCloseModal(false)
  }

  function eraseStore() {
    // console.log('Erasing!!!')
    var zeroedData = {}

    for (var member in state.rentalApplication) {
      // console.log(member)
      zeroedData[member] = defaultRentalApplicationValues[member]
    }
    actions.updateAction(zeroedData);
  }

  function quitApplication() {
    eraseStore()
    
    hideConfirmCloseModal()
    push('/')
  }

  function getPageToRender() {
    switch (currentPage) {
        case 1:
            return <ContactInfo incrementPage={incrementCurrentPage} />
        case 2:
            return <LengthOfStay incrementPage={incrementCurrentPage} />
        case 3:
            return <CurrentAddress incrementPage={incrementCurrentPage} />
        case 4:
            return <Employment incrementPage={incrementCurrentPage} />
        case 5:
            return <AdditionalOccupantsCard incrementPage={incrementCurrentPage} />
        case 6:
            return <Questionnaire incrementPage={incrementCurrentPage} eraseStore={eraseStore} />

        default:
            // setCurrentPage(1)
            // console.log('wtf');
            return <Redirect to="/error" />

    }
  }

  useEffect(() => {
    return history.listen((location) => {
      // console.log(history.action, "action");

      if (history.action === "POP") {
        setCurrentPage(currentPage - 1);
      } else {
      }
    });
  }, [currentPage, history]);

  function decrementCurrentPage() {
    // setCurrentPage(currentPage - 1)
    history.goBack();
  }

  function incrementCurrentPage() {
    setCurrentPage(currentPage + 1);
    push("#");
    // console.log(state)
  }

  if(searchingForApt) {
    return <h1>LOADING</h1>
  }

  return (
    <>

{currentPage > numPages ? <Thanks /> : 
(
  <>



    {(apartmentInfo != null && apartmentInfoViewed === false) ? <ApartmentInfoPage apartmentInfo={apartmentInfo} setApartmentInfoViewed={setApartmentInfoViewed} incrementPage={incrementCurrentPage} /> :

    <>

   
        <div className="BCA-application-wrapper">
          <Container className="BCA-header-container">
            <Row className="BCA-header-row">
              <Col className="vertical-row header-left">



                {currentPage > 1 ? (
                  <Button
                    variant="link backButton"
                    onClick={decrementCurrentPage}
                  >
                    <IoMdArrowBack />
                  </Button>
                ) : null}
              </Col>

              <Col className="text-center vertical-row header-counter header-middle">
                {currentPage} of {numPages}
              </Col>

              <Col className="text-right vertical-row header-right">
                <Button
                  variant="link closeButton"
                  onClick={showConfirmCloseModal}
                >
                  <IoMdClose />
                </Button>
              </Col>
            </Row>
          </Container>
          <div className="BCA-application">
          {getPageToRender()}
          </div>
        </div>

        <ConfirmCloseDialog openConfirmCloseModal={openConfirmCloseModal} hideConfirmCloseModal={hideConfirmCloseModal} quitApplication={quitApplication}/>
 
    </>
}
</>
)}
</>
    
  );
}










const DarkTheme = createMuiTheme({
  palette: {
    type: 'dark',
  }
  })

function ConfirmCloseDialog({openConfirmCloseModal, hideConfirmCloseModal, quitApplication}) {
  return (

    <ThemeProvider theme={DarkTheme}>
    <Dialog
        open={openConfirmCloseModal}
        onClose={hideConfirmCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Exit"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to exit, your application will not be submitted?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideConfirmCloseModal} className="btn btn-secondary">Go Back</Button>
          <Button onClick={quitApplication} className="btn btn-danger" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      </ThemeProvider>
  )
}