import React, { useState, useEffect } from "react";
import "../styles/NavBarStyles.css";

function NavBarContents(props) {
  return (
    <header className="cd-header">
      <div className="header-wrapper">
        <div className="logo-wrap">
          <a href="/home" className="hover-target">
            <span>Buffalo City</span>
            <br />
            Apartments{" "}
          </a>
        </div>
        <div className="nav-but-wrap" onClick={props.handleMenuStatusChange}>
          <div className="menu-icon hover-target">
            <div id="nav-icon3" className={props.hamburgerClass}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

function NavBarLinks() {
  return (
    <div className="nav">
      <div className="nav__content">
        <ul className="nav__list">
          {/* todo figure out how to set active link class active-nav, for now just make them all active*/}
          <li className="nav__list-item active-nav">
            <a href="/home" className="hover-target">
              home
            </a>
          </li>
          <li className="nav__list-item active-nav">
            <a href="/apply" className="hover-target">
              apply
            </a>
          </li>
          <li className="nav__list-item active-nav">
            <a href="/about-us" className="hover-target">
              about us
            </a>
          </li>
          <li className="nav__list-item active-nav">
            <a href="/contact" className="hover-target">
              contact
            </a>
          </li>
          <li className="nav__list-item active-nav">
            <a href="/sign-in" className="hover-target">
              sign in
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

function NavBar() {
  // State variables to hold values for if the menu is open and to update the class for the hamburger to change its shape
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hamburgerClass, setHamburgerClass] = useState(null);

  function handleMenuStatusChange() {
    setIsMenuOpen(!isMenuOpen);
  }

  useEffect(() => {
    // This fires after the isMenuOpen state changes so every time the user clicks the nav menu button.
    // We update the hamburgerClass state variable to correspond with the isMenuOpen state variable and  we also
    // add/remove a css class 'nav-active' to the body to make the nav menu take up the entire screen
    isMenuOpen ? setHamburgerClass("open") : setHamburgerClass(null);
    isMenuOpen
      ? document.body.classList.add("nav-active")
      : document.body.classList.remove("nav-active");
  }, [isMenuOpen]);

  return (
    <div>
      <NavBarContents
        handleMenuStatusChange={handleMenuStatusChange}
        hamburgerClass={hamburgerClass}
      />
      <NavBarLinks />
    </div>
  );
}

export default NavBar;
