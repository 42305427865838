import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/customStyles.css"
import "./styles/applicationStyles.css"
import "./styles/landingPageStyles.css"
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import NavBar from "./components/NavBar";
import LandingPage from "./components/LandingPage";
import { Page404, PageError } from "./components/Pages"
import { RentalApplication } from "./components/RentalApplication"
import { StateMachineProvider } from "little-state-machine";

import firebase from "./components/RentalApplicationComponents/firebase/firestore"
export const analytics = firebase.analytics();

function App() {
  return (
    <>
      <Router>
        <StateMachineProvider>
        <Switch>
        <Route path="/apply" exact component={RentalApplication} />
        <Route path="/apply/:apartmentId" exact component={RentalApplication} />

        <Route component={DefaultContainer} />
        </Switch>
        </StateMachineProvider>
      </Router>
    </>
  );
}

const DefaultContainer = () => (
  <>
    <NavBar />
    <Switch>
    <Route path="/" exact component={LandingPage} />
    <Route path="/home" exact component={LandingPage} />
    <Route path="/error" exact component={PageError} />
    {/* <Route path="/about-us" exact component={MyUnderConstructionPage} />   */}
    {/* <Route path="/contact" exact component={MyUnderConstructionPage} />  */}
    {/* <Route path="/sign-in" exact component={MyUnderConstructionPage} />   */}
    <Route component={Page404} />
    </Switch>
  </>
)

export default App;
