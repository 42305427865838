import React, {useEffect} from "react";
import { useForm } from "react-hook-form";
// import { useHistory } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "./StateMachine/updateAction"
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MyTextInput, MyDateInput, MyPhoneInput} from "./Inputs/inputs"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DevTool } from "@hookform/devtools";
import { analytics } from "../../App"

const current = new Date();
const todays_date = `${("0" + (current.getMonth() + 1)).slice(-2)}/${(
  "0" + current.getDate()
).slice(-2)}/${current.getFullYear()}`;

// console.log(todays_date, 'td');

const SignupSchema = yup.object().shape({
  contactInfo:  yup.object({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    // phone: yup.string().required("Required"),
    // DoB: yup.string().min(10, "Enter valid date mm/dd/yyyy").nullable('Required').required("Required"),
    // DoB: yup.string().notOneOf(['Invalid Date'], 'Invalid Date').nullable('Required').required("Required"),
    DoB: yup.date().typeError('Invalid Date').max(todays_date, 'Must be before today'),
    email: yup.string().email("Invalid email address").required("Required"),
    phone: yup.string().required("Required").matches(/^(1-)?\d{3}-\d{3}-\d{4}$/, 'Phone number is not valid'),
  })
  });


export const  ContactInfo = props => {

    const { state, actions } = useStateMachine({updateAction});
    const { handleSubmit, errors, register, control, setValue } = useForm({
        resolver: yupResolver(SignupSchema),
        mode: "onBlur",
        defaultValues: state.rentalApplication,
        reValidateMode: 'onBlur',
    });

    // console.log(errors, 'errors');


    const onSubmit = data => {

        analytics.logEvent('Contact Info Data', {...data.contactInfo});
        actions.updateAction(data);
        // console.log("lets go to #2");
        // console.log(data, 'ldata')
        props.incrementPage()
    }

    // console.log(state.rentalApplication, 'defaultValues')

    useEffect(() => {
      document.querySelector('body').scrollTo(0, 0)
      analytics.logEvent('Contact Info');
      }, [])

    return (
        <>
        <form onSubmit={handleSubmit(onSubmit)}>
            <h1 className="form-header">Contact Info</h1>
            <h2 className="form-subtitle">Who is filling out this application?<br />We will use this information to reach out to you if your accepted. We will not sell any your personal information. The information you provide will soley be used for determining eligibility for one of our rental units.</h2>
                <Card id="yourInfo" className="applicationCard">
          {/* <Card.Header>Your Info</Card.Header> */}
          <Card.Body>
            <Row>
              <Col>
                {/* prettier-ignore */}
                <MyTextInput name="contactInfo.firstName" label="First Name" ref={register} error={errors?.contactInfo?.firstName} />
              </Col>
              <Col>
                {/* prettier-ignore */}
                <MyTextInput name="contactInfo.middleName" label="Middle Name" ref={register} error={errors?.contactInfo?.middleName} optional />
              </Col>
              <Col>
                {/* prettier-ignore */}
                <MyTextInput name="contactInfo.lastName" label="Last Name" ref={register} error={errors?.contactInfo?.lastName} />
              </Col>
            </Row>
            <Row>
              <Col>
                {/* prettier-ignore */}
                <MyDateInput name="contactInfo.DoB" label="Date of Birth"  error={errors?.contactInfo?.DoB} control={control} setValue={setValue} />
              </Col>
              <Col>
                {/* prettier-ignore */}
                <MyTextInput name="contactInfo.email" label="Email Address" ref={register} error={errors?.contactInfo?.email} />
              </Col>
              <Col>
                {/* prettier-ignore */}
                <MyPhoneInput name="contactInfo.phone" label="Phone Number" ref={register} error={errors?.contactInfo?.phone} control={control} />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        

        <div className="continue-area text-center">
        <input className="continueButton" type="submit" value="Continue" />
          </div>

        </form>

        <DevTool control={control} />
        </>
    );
}