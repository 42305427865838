import React, {  useEffect } from "react";
import { useForm } from "react-hook-form";
// import { useHistory } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "./StateMachine/updateAction"
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MyDateInput, MySelectPicker} from "./Inputs/inputs"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LeaseTerms } from "./selectors/selectors";
import { DevTool } from "@hookform/devtools";
import { analytics } from "../../App"

var date = new Date()


const SignupSchema = yup.object().shape({
  lengthOfStay: yup.object({
    leaseTerm: yup.object().shape({
      value: yup.string().required('Required'),
      label: yup.string().required('Required')
    }).required('Required').nullable('Required'),
    moveInDate: yup.date().typeError('Invalid Date').min(date, 'Must be in future'),
})

  });


export const  LengthOfStay = props => {
    console.log('Length Of Stay')

    const { state, actions } = useStateMachine({updateAction});
    const { handleSubmit, errors, control, setValue } = useForm({
        resolver: yupResolver(SignupSchema),
        mode: "onBlur",
        defaultValues: state.rentalApplication,
        reValidateMode: 'onBlur',
    });
    // const { push }  = useHistory();
    const onSubmit = data => {
        actions.updateAction(data);
        // console.log(data, 'lengthOfStay')

        props.incrementPage()
    }

    useEffect(() => {
      document.querySelector('body').scrollTo(0, 0)
      analytics.logEvent('Length Of Stay');
    }, [])

    return (
        <>
        <form onSubmit={handleSubmit(onSubmit)}>
        <h1 className="form-header">Length of Stay</h1>
            <h2 className="form-subtitle">How long of a lease term are you looking for?</h2>
          <Card id="lengthOfStay" className="applicationCard">
          {/* <Card.Header>Length of Stay</Card.Header> */}
          <Card.Body>
            <Row>
              <Col>
                {/* prettier-ignore */}
                <MyDateInput name="lengthOfStay.moveInDate" label="Desired Move In Date"  error={errors?.lengthOfStay?.moveInDate} control={control} setValue={setValue} />
              </Col>
              <Col>
                {/* prettier-ignore */}
                <MySelectPicker name="lengthOfStay.leaseTerm" label="Lease Term"  error={errors?.lengthOfStay?.leaseTerm} control={control} options={LeaseTerms}/>
              </Col>

            </Row>
          </Card.Body>
        </Card>

        <div className="continue-area text-center">
        <input className="continueButton" type="submit" value="Continue" />
          </div>
        </form>

        <DevTool control={control} />
        </>
    );
}