import { Button } from 'react-bootstrap';

function LandingPage() {
  return (
    <div className="BCA-landing-page section full-height over-hide">
      <div className="switch-wrap">
        <h2 className="landing-main-content">WELCOME TO</h2>
        <h2 className="landing-main-content-big">BUFFALO CITY APARTMENTS</h2>
        <Button
          className="landing-main-button"
          variant="outline-info"
          size="lg"
          href="/apply"
        >
          Apply Now
        </Button>

        <div className="landing-note">
          <p>
            Please use the link given in the listing. <br />
            Otherwise click Apply Now for general consideration of any of our
            available units.
          </p>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;