import Button from "react-bootstrap/Button";
import NavBar from "./NavBar"
import { useStateMachine } from "little-state-machine";
import updateAction from "./RentalApplicationComponents/StateMachine/updateAction"



// http://localhost:3002/apply/xxx

export function ApartmentInfoPage({apartmentInfo, incrementPage, setApartmentInfoViewed}) {
    const { actions } = useStateMachine({updateAction})
    

    function letsGo() {
        // incrementPage()
        setApartmentInfoViewed(true)
        actions.updateAction({apartmentInfo: apartmentInfo});
    }

    return(
        <>
        <NavBar />

        <div className="center-main-content">
        <h1 className="applicationInfoMain">{apartmentInfo?.address} {apartmentInfo?.address2}</h1>
        <h3 className="applicationInfoSub">{apartmentInfo?.city}, {apartmentInfo?.state} {apartmentInfo?.zipCode}</h3>
        <div className="applicationInfoSubInfo">
        <span>${apartmentInfo?.rent}</span>
        <span style={{ paddingLeft: "1vw", paddingRight: "1vw" }}>
          |
        </span>
        <span>{apartmentInfo?.bedrooms} BR</span>
        <span style={{ paddingLeft: "1vw", paddingRight: "1vw" }}>
          |
        </span>
        <span>{apartmentInfo?.sqft}sft</span>{" "}
        </div>

        <Button
          className="landing-main-button"
          variant="outline-info"
          size="lg"
          onClick={letsGo}
        >
          Apply Now
        </Button>
        
        </div>



      </>
    )

}