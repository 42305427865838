import React from "react";
import { useForm} from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import updateAction from "../StateMachine/updateAction"
import updateModal from "../StateMachine/updateModal"
import { MyTextInput } from "../Inputs/inputs"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "react-bootstrap/Button";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import { IoMdClose } from "react-icons/io";


import { DevTool } from "@hookform/devtools";





const SignupSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  age: yup.number().typeError('Invalid Age').max(130, 'Invalid Age').required("Required"),
  relationship: yup.string().required("Required"),
  job: yup.string().required("Required"),
  });




export function AdditionalOccupantModal() {

  //Coming in
  const { actions, state} = useStateMachine({updateModal, updateAction})
  // const occupants = state.rentalApplication.additionalOccupants   // todo call occupants roommates
  const modalState = state.modalState
  const closeModal = () => actions.updateModal({visible: false})

  // console.log(modalState, 'this is the modal state')

  // console.log(state, 'state')



    // const { state, actions } = useStateMachine({updateAction});
    const { handleSubmit, register, control, errors} = useForm({
        resolver: yupResolver(SignupSchema),
        mode: "onBlur",
        defaultValues: state.rentalApplication.additionalOccupants[state.modalState.additionalOccupant]
    });
    // const numRoomates = state.rentalApplication.additionalOccupants.length

    const deleteRoommate = () => {
      let myData = state.rentalApplication.additionalOccupants
      myData.splice(state.modalState.additionalOccupant, 1)
      actions.updateAction({additionalOccupants: myData});
      closeModal()
    }

    const onSubmit = data => {
      let myData = state.rentalApplication.additionalOccupants
      // console.log(myData, 'myData before')
      myData = [...myData, data]
      // console.log(myData, 'myData after')
      actions.updateAction({additionalOccupants: myData});
      closeModal()
    }

    const DarkTheme = createMuiTheme({
      palette: {
        type: 'dark',
      }
      })


    return (
        <>
        <DevTool control={control} />
        {/* `additionalOccupants[${numRoomates}]` */}






    <ThemeProvider theme={DarkTheme}>
    <Dialog
        open={modalState.visible}
        onClose={closeModal}
        fullWidth={true}
        // aria-labelledby="alert-dialog-title"
        // aria-describedby="alert-dialog-description"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">
        
        <Button
                  variant="link"
                  className="ModalCloseButton"
                  onClick={closeModal}
                >
                  <IoMdClose />
                </Button>

                
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">


          <MyTextInput
          label="First Name"
          name="firstName"
          ref={register}
          error={errors?.firstName}
        />
        <MyTextInput
          label="Last Name"
          name="lastName"
          ref={register}
          error={errors?.lastName}
        />
          <MyTextInput
          label="Age"
          name="age"
          ref={register}
          error={errors?.age}
        />
        <MyTextInput
          label="Relationship"
          name="relationship"
          placeholder="friend/brother"
          ref={register}
          error={errors?.relationship}
        />
        <MyTextInput
          label="Job"
          name="job"
          ref={register}
          error={errors?.job}
        />
        
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {state.modalState.editMode ? <><Button as="input" variant="danger" className="modalButton" onClick={deleteRoommate} value="Delete" />{" "}</> : null }
            <Button as="input" type="submit" className="modalButton addOccupantButtonModal" value="Submit" />{" "}
        </DialogActions>
        </form>
      </Dialog>
      </ThemeProvider>







        </>
    );
}