
import React, {  useEffect } from "react";
import { useForm } from "react-hook-form";
// import { useHistory } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "./StateMachine/updateAction"
import Card from "react-bootstrap/Card";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import { MyDateInput, MySelectPicker} from "./Inputs/inputs"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import { LeaseTerms } from "./selectors/selectors";

import { YesNoInput } from "./Inputs/inputs"
import { DevTool } from "@hookform/devtools";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import emailjs from "emailjs-com";
import firebase from "./firebase/firestore";
import { analytics } from "../../App"



const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));




function getAge(birthDate) {
  // console.log(birthDate, 'birthdate')
  var today = new Date();
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}






const SignupSchema = yup.object().shape({
  questions: yup.object({
    smoke: yup.string().required('Required'),
    animal: yup.string().required('Required'),
    felony: yup.string().required('Required'),
    funds: yup.string().required('Required'),
  })
  });


export const  Questionnaire = props => {

    const { state, actions } = useStateMachine({updateAction});

    const { handleSubmit, errors, control } = useForm({
        resolver: yupResolver(SignupSchema),
        mode: "onBlur",
        defaultValues: state.rentalApplication,
        reValidateMode: 'onBlur',
    });
    const [submitting, setSubmitting] = React.useState(false);

    const classes = useStyles();
    // const { push }  = useHistory();




    function sendEmail(e) {
      // e.preventDefault();
    
      emailjs
        .send(
          "service_cm5cdas",
          "template_0isq2rl",
          e,
          "user_LisAwWN0Kv5fZVYQO8uT3"
        )
        .then(
          (result) => {
            // console.log(result.text);
            analytics.logEvent('Send Email');
            updateDatabase(e)
          },
          (error) => {
            // console.log(error.text);
            analytics.logEvent('SEND EMAIL ERROR!!!');
          }
        );
    }
    
    function updateDatabase(values) {
      const db = firebase.firestore();
    
              //ADD
              db.collection("applications2").add(values)
              .then((docRef) => {
                // console.log("Document written with ID: ", docRef.id);
                  setSubmitting(false)
                  props.incrementPage()
                  props.eraseStore()
                  analytics.logEvent('Firebase DB Save');
              })
              .catch((error) => {
                  console.error("Error adding document: ", error);
                  analytics.logEvent('FIREBASE DB ERROR!!!');
              });
    
              // db.collection("test_collection")
              //   .get()
              //   .then((querySnapshot) => {
              //     querySnapshot.forEach((doc) => {
              //       console.log(`${doc.id} => ${doc.data()}`);
              //     });
              //     setSubmitting(false)
              //     props.incrementPage()
              //     props.eraseStore()
                  

                  
              //   });
    
          // let i = 0;
          // while(i < 1000) {
          //   console.log(i)
          //   i++;
          // }
    }
    



    const onSubmit = data => {
        actions.updateAction(data);
        // console.log(data, 'questionnaire')
        // console.log(state, 'state')

        analytics.logEvent('Submit');

        // Update some values
        var applicationData = state.rentalApplication
        applicationData.questions = data.questions; //should not have to do this but idk wtf is happening
  
        if ( applicationData.lengthOfStay.leaseTerm.hasOwnProperty('value')) {
          // Only do this once, should not happen twice, but just in case

          applicationData.contactInfo.age = getAge(applicationData.contactInfo.DoB)


          var DoB = applicationData.contactInfo.DoB
          DoB = ((DoB.getMonth() > 8) ? (DoB.getMonth() + 1) : ('0' + (DoB.getMonth() + 1))) + '/' + ((DoB.getDate() > 9) ? DoB.getDate() : ('0' + DoB.getDate())) + '/' + DoB.getFullYear()
          applicationData.contactInfo.DoB = DoB

          var moveInDate = applicationData.lengthOfStay.moveInDate
          moveInDate = ((moveInDate.getMonth() > 8) ? (moveInDate.getMonth() + 1) : ('0' + (moveInDate.getMonth() + 1))) + '/' + ((moveInDate.getDate() > 9) ? moveInDate.getDate() : ('0' + moveInDate.getDate())) + '/' + moveInDate.getFullYear()
          applicationData.lengthOfStay.moveInDate = moveInDate



          applicationData.additionalOccupantsJSON = JSON.stringify(applicationData.additionalOccupants)


          applicationData.currentAddress.renting = applicationData.currentAddress.renting.value
          if(applicationData.currentAddress.renting === 'Yes') { 
            applicationData.currentAddress.rentUpToDate = applicationData.currentAddress.rentUpToDate.value
          }
          
          
          applicationData.currentAddress.state = applicationData.currentAddress.state.value
          applicationData.currentAddress.lengthOfStay = applicationData.currentAddress.lengthOfStay.value
          applicationData.currentAddress.student = applicationData.currentAddress.student.value
          applicationData.lengthOfStay.leaseTerm = applicationData.lengthOfStay.leaseTerm.value
          

          if(applicationData.work.employed === 'Yes') { 
            applicationData.work.payFreq = applicationData.work.payFreq.value
            applicationData.work.salaryConsistent = applicationData.work.salaryConsistent.value
            applicationData.work.state = applicationData.work.state.value
            applicationData.work.haveAdditionalInfo = applicationData.work.haveAdditionalInfo.value

            var startDate = applicationData.work.startDate
            startDate = ((startDate.getMonth() > 8) ? (startDate.getMonth() + 1) : ('0' + (startDate.getMonth() + 1))) + '/' + ((startDate.getDate() > 9) ? startDate.getDate() : ('0' + startDate.getDate())) + '/' + startDate.getFullYear()
            applicationData.work.startDate = startDate
          }
        }

      
        applicationData.timestamp = new Date();

        // console.log(applicationData)



        // NEED TO SET THIS TO TRUE, THEN SEND EMAIL AND UPDATE DATABASE, THEN INCREMENT PAGE,  NEXT PAGE SHOULD BE SUCCESS, THEN CLEAR PAGE DATA
        setSubmitting(true)
         sendEmail(applicationData);

        // console.log(JSON.stringify(applicationData))
        // alert(JSON.stringify(applicationData))


         

        

                
    }

    useEffect(() => {
      document.querySelector('body').scrollTo(0, 0)
      analytics.logEvent('Questionnaire');
    }, [])

    return (
        <>
                <DevTool control={control} />
        <form onSubmit={handleSubmit(onSubmit)}>
        <h1 className="form-header">Questionnaire</h1>
            <h2 className="form-subtitle">Please answer these few questions.</h2>
          <Card id="questionnaire" className="applicationCard">
          {/* <Card.Header>Length of Stay</Card.Header> */}
          <Card.Body>



          <YesNoInput name="questions.smoke" label="Do you or any of your occupants smoke?" error={errors?.questions?.smoke} control={control} />
          <YesNoInput name="questions.animal" label="Do you or any of your occupants have an animal?" error={errors?.questions?.animal} control={control} />
          <YesNoInput name="questions.felony" label="Have you ever been convicted of a felony?" error={errors?.questions?.felony} control={control} />
          <YesNoInput name="questions.funds" label="Do you have the required funds to move in?" error={errors?.questions?.funds} control={control} />



          
          </Card.Body>
        </Card>

        <div className="continue-area text-center">
        <input className="continueButton" type="submit" value="Submit Application" />
          </div>

        </form>

        <Backdrop className={classes.backdrop} open={submitting} >
          <CircularProgress color="inherit" />
        </Backdrop>
       
        </>
    );
}